






import {
  Component,
  Vue,
  Prop,
  PropSync,
  Model,
  Watch,
  Provide,
  Inject,
  Emit,
  Ref,
} from "vue-property-decorator";
@Component({})
export default class operation_config extends Vue {}
/** S @Prop **/
/** E @Prop **/
/** S Data **/
/** E Data **/
/** S @METHODS **/
/** E @METHODS **/
/** S @Watch **/
/** E @Watch **/
/** S @Emit **/
/** E @Emit **/
/** S @created **/
/** E @created **/
/** S @mounted **/
/** E @mounted **/
